<template>
  <div class="pl-6 w-full overflow-x-auto">
    <div
      class="w-full max-w-3xl"
    >
      <div class="bg-custom-gray-10 px-3 py-2 leading-4 rounded-t-sm">
        <span class="font-semibold text-sm">
          Enforce Licensee Sample
        </span>
      </div>
      <div
        v-if="!initCompleted"
        class="w-full py-32 text-center border border-custom-gray-8 border-t-0"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <form
        class="relative py-7 px-32 border border-custom-gray-8 border-t-0"
      >
        <base-auto-complete-input
          v-model="submissionRequestData.licenseeName"
          :input-props="{
            type: 'text',
            containerClass: 'h-full w-full',
            placeholder: 'Name of Licensee'
          }"
          class="w-full mb-4 flex justify-between items-center"
          input-container-class="h-full w-full max-w-xs"
          label="Licensee"
          :options="licenseeNameOptions"
          :disabled="licenseeDisabled"
          :disable-suggestions="submissionRequestData.licenseeName.length < 2"
        />
        <base-select
          v-model="submissionRequestData.enforceSample"
          class="w-full mb-4 flex justify-between items-center text-sm text-left"
          dropdown-btn-container-class="relative w-full max-w-xs"
          placeholder="-- SELECT --"
          label="Enforce Sample"
          :disabled="!submissionRequestData.licenseeName"
          show-default-option
          :options="enforceSampleOptions"
        />

        <div class="text-center">
          <base-button
            variant="btn-primary"
            class="mr-4"
            type="button"
            text="Override Sample"
            :disabled="!isEnforceBtnActive || isFormLoading"
            @click="handleFormSubmit()"
          />
          <base-button
            variant="btn-link"
            text="Reset"
            type="button"
            :is-loading="isFormLoading"
            @click="handleReset()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, reactive, ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useToastNotifications from '@/hooks/toastNotifications.js';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import { ERROR, SUCCESS, ENFORCE_SAMPLE_FAILED, ENFORCE_SAMPLE_SUCCESS } from '@/constants/alerts';

export default {
    name: 'EnforceSample',

    components: {
        BaseAutoCompleteInput: defineAsyncComponent(() => import('@/components/generic-components/BaseAutoCompleteInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { submissionFormMode } = useSubmissionForm();
        const { notificationsStack } = useToastNotifications();

        const userLicensees = computed(() => store.getters['auth/getUserLicensees']);
        const selectedLicenseeName = computed(() => store.getters['auth/getSelectedLicensee']?.licenseeName || '');

        // submission request logic
        const submissionRequestData = reactive({
            licenseeName: '',
            enforceSample: ''
        });

        const enforceSampleOptions = ref([
            'Yes',
            'No'
        ]);
        const isSubmissionRequestDataValid = computed(() => {
            const submissionRequestValues = Object.values(submissionRequestData);
            return submissionRequestValues.filter(value => value).length === submissionRequestValues.length && licenseeNameOptions.value.includes(submissionRequestData.licenseeName);
        });

        // licensee name logic
        const licenseeNameOptions = computed(() => {
            // ....TO DISCUSS WITH ARSALAN....//
            // if (userLicensees?.value && userLicensees.value.length) {
            //     return userLicensees.value.map(l => l.licenseeName);
            // } else {
            const licensees = store.getters['baLicensee/getLicensees'];
            return licensees && licensees.length ? licensees.map(name => name.licensee) : [];
            // }
        });
        const isEnforceBtnActive = computed(() => submissionRequestData?.licenseeName?.length > 0 && submissionRequestData?.enforceSample?.length > 0);
        const licenseeNameOptionsLoading = ref(false);
        const fetchLicenseeNameOptions = async () => {
            try {
                if (licenseeNameOptionsLoading.value) {
                    return;
                }

                licenseeNameOptionsLoading.value = true;

                await store.dispatch('baLicensee/fetchDrmLicenseesList', {
                    params: {
                        name: submissionRequestData.licenseeName
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                licenseeNameOptionsLoading.value = false;
            }
        };

        watch(
            () => submissionRequestData.licenseeName,
            () => {
                if (submissionRequestData.licenseeName.length >= 2) fetchLicenseeNameOptions();
            }
        );

        const licenseeId = computed(() => {
            const licensees = store.getters['baLicensee/getLicensees'];
            return (licensees?.find(el => submissionRequestData && submissionRequestData.licenseeName === el.licensee) || { licenseeId: '' }).licenseeId;
        });

        //  const doesUserHaveAssociatedLicensees = computed(() => store.getters['auth/doesUserHaveAssociatedLicensees']);

        // reset form logic
        const handleReset = async () => {
            submissionRequestData.licenseeName = '';
            submissionRequestData.enforceSample = '';
        };

        const licenseeDisabled = ref(false);

        watch(
            () => licenseeId.value,
            () => {
                const licensees = store.getters['baLicensee/getLicensees'];
                const selectedLicensee = licensees?.filter(lic => lic.licenseeId === licenseeId.value);
                const sampleEnforced = selectedLicensee[0]?.samples_config?.isSamplesRequired;
                if (sampleEnforced === 'Y') submissionRequestData.enforceSample = 'Yes';
                else if (sampleEnforced === 'N') submissionRequestData.enforceSample = 'No';
                else submissionRequestData.enforceSample = '';
            }
        );

        // const checkForUserLicensees = async () => {
        //     if (selectedLicenseeName.value) {
        //         submissionRequestData.licenseeName = selectedLicenseeName.value;
        //         await fetchLicenseeNameOptions();
        //         licenseeDisabled.value = true;
        //     }
        // };

        // submit form logic
        const isFormLoading = ref(false);
        const currentUser = computed(() => store.getters['auth/getUserInfo']);
        const handleFormSubmit = async () => {
            try {
                isFormLoading.value = true;
                // console.log(`licenseeId... ${JSON.stringify(licenseeId.value)}`);
                await store.dispatch('baLicensee/drmEnforceSample', {
                    bodyPayload: {
                        licenseeId: licenseeId.value,
                        enforceSample: submissionRequestData.enforceSample
                    }
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: ENFORCE_SAMPLE_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: ENFORCE_SAMPLE_FAILED
                });
            } finally {
                isFormLoading.value = false;
            }
        };

        const initCompleted = ref(false);

        onMounted(async () => {
            submissionFormMode.value = 'NEW';
            await handleReset();
            initCompleted.value = true;
        });

        return {
            submissionRequestData,
            licenseeNameOptions,
            handleReset,
            isFormLoading,
            handleFormSubmit,
            isSubmissionRequestDataValid,
            initCompleted,
            licenseeDisabled,
            isEnforceBtnActive,
            enforceSampleOptions
        };
    }
};
</script>
